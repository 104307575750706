import React from "react";
import style from "./Header.module.css"
import Logo from "./Logo/Logo";
import MakeCall from "./MakeCall/MakeCall";
import NavBar from "./NavBar/NavBar";
import Mail from "./Mail/Mail";


const Header = () => {
    return (
        <div className={style.header_container}>
            <div className={style.header}>
                <MakeCall/>
                <Logo/>
                <NavBar/>
                <Mail/>
            </div>
        </div>
    )
}


export default Header