import React from "react";
import style from "./Contacts.module.css";
import Contact from "./Contact/Contact";
import img1 from "../images/contacts/1.jpg";
import img2 from "../images/contacts/2.jpg";
import img3 from "../images/contacts/3.jpg";

const Contacts = () => {
    return (
        <div className={style.contacts}>
            <div className={style.management}>
                <h1>Руководство</h1>
            </div>
            <div className={style.contacts_container}>
                <Contact
                    img={img2}
                    alt="Порошин Николай Валентинович"
                    name="Порошин Николай Валентинович"
                    info="Заместитель директора по строительству"
                    phone_number="8 (962) 710 47 75"
                    mail=""
                />
                <Contact
                    img={img3}
                    alt="Войпе Василина Романовна"
                    name="Войпе Василина Романовна"
                    info="Заместитель генерального директора по экономике"
                    phone_number="8 (964) 328 66 1"
                    mail="vasenka2010@mail.ru"
                />
            </div>
        </div>
    );
};

export default Contacts;
