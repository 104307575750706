import React from "react";
import style from './Logo.module.css'

const Logo = () => {
    return (
        <div className={style.logo_container}>
            <div className={style.logo}>
                <div className={style.region}>
                    <div className={style.region_background}>регион</div>
                </div>
                <div className={style.west_north}>
                    Северо-Запад
                </div>
            </div>
        </div>
    )
}

export default Logo;