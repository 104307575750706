import React from "react";
import style from './NotFound.module.css'


const NotFound = () => {
    return (
        <div className={style.not_found}>
            <h1>Извините, страница не найдена</h1>
            <div className={style.img_404}>404</div>
        </div>
    )
}


export default NotFound