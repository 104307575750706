import React from "react";
import style from './MakeCall.module.css'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPhone} from "@fortawesome/free-solid-svg-icons";

const MakeCall = ()=>{
    return(
        <div className={style.make_call}>
            <div className={style.icon}>
                <FontAwesomeIcon icon={faPhone}/>
            </div>
            <div className={style.info}>
                <div>Позвоните нам</div>
                <a href='#'>+7(964) 328-66-61</a>
            </div>
        </div>
    )
}


export default MakeCall;