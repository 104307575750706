import React from "react";
import style from './Mail.module.css'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelopeOpen} from "@fortawesome/free-solid-svg-icons";

const Mail = () => {
    return (
        <div className={style.mail}>
            <div className={style.icon}>
                <FontAwesomeIcon icon={faEnvelopeOpen}/>
            </div>
            <div className={style.info}>
                <div>Напишите нам</div>
                <a href='#'>RegionS-Z@yandex.ru</a>
            </div>

        </div>
    )
}


export default Mail;