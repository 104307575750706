import React from "react";
import style from "./Contact.module.css"


const Contact = (props) => {
    const tel = 'tel:' + props.phone_number
    return (
        <div className={style.contact}>
            <div className={style.contact_img}>
                <img src={props.img} alt={props.alt}/>
            </div>
            <div className={style.contact_info}>
                <h3>{props.name}</h3>
                <p>{props.info}</p><br/>
                <a className={style.phone_number} href ={tel}>{props.phone_number}</a><br/>
                <span className={style.mail}>{props.mail}</span>
            </div>
        </div>
    )
}

export default Contact;