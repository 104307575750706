import React from "react";
import style from './AdditionalDocument.module.css';
import {faFileLines} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";


const Document = (props)=>{
    return(
        <div  className={style.additional_document}>
            <a href={props.href}>
                <FontAwesomeIcon icon={faFileLines} />
            </a>
            <a href={props.href}>
                {props.name}
            </a>
        </div>
    )
}


export default Document;