import React from "react";
import styles from "./Home.module.css"
import Objects from "../Objects/Objects";

const Home = ()=>{
    return(
        <div className={styles.home}>
            <Objects/>
        </div>
    )
}

export default Home;