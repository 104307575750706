import React from "react";
import style from './ObjectInfo.module.css'
import {useLocation} from 'react-router-dom'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faLocationDot} from "@fortawesome/free-solid-svg-icons";
import {NavLink} from "react-router-dom";
import Carousel from 'react-bootstrap/Carousel';

function isEmptyObject(obj) {
    for (var i in obj) {
        if (obj.hasOwnProperty(i)) {
            return false;
        }
    }
    return true;
}

const ObjectInfo = () => {
    const location = useLocation()

    const {jk} = location.state
    const {name} = location.state
    const {slides} = location.state
    const {description} = location.state
    const {stages} = location.state
    const {date_start} = location.state
    const {bricks} = location.state
    const {date_end} = location.state
    const {customer} = location.state
    const {address} = location.state
    const list_slides = slides.map((slide) =>
        <Carousel.Item interval={3200}>
            <div className={style.carousel_block}>
                <img
                    className={style.carousel_img}
                    src={slide}
                    alt=""
                />
            </div>
        </Carousel.Item>
    );
    let list_stages = ''
    if (isEmptyObject(stages)) {
        list_stages = stages.map((stage) =>
            <li>{stage}</li>
        );
    }


    return (
        <div className={style.object_info}>
            <div className={style.tittle}>
                <h1>{name}. {jk}</h1>

                <div className={style.nav}>
                    <NavLink to='/' className={({isActive}) => isActive ? style.active : ''}>назад</NavLink>
                </div>
            </div>
            <div className={style.container}>
                {/* bootstrap slider  */}
                <Carousel className={style.slider}>
                    {list_slides}
                </Carousel>
                <div className={style.main_info}>
                    <h2>{name}</h2>
                    <div className={style.regular_text}>
                        {description}
                        <ul className={style.list}>
                            {list_stages}
                        </ul>
                    </div>
                    <div className={style.info_block}>
                        <div className={style.date}>
                            <div className={style.light_text}>ПЕРИОД РАБОТ</div>
                            <div className={style.fat}>
                                <div><span className={style.list_dot} style={{background: 'rgb(194, 104, 104)'}}></span>
                                    {date_start}
                                </div>
                                <div><span className={style.list_dot} style={{background: 'rgb(194, 104, 104)'}}></span>
                                    {date_end}
                                </div>
                            </div>
                        </div>
                        <div className={style.bricks}>
                            <div className={style.light_text}>КЕРПИЧНАЯ КЛАДКА</div>
                            <span className={style.fat}> {bricks}</span>
                        </div>
                    </div>
                    <div className={style.customer}>
                        <div className={style.light_text}>ЗАКАЗЧИК</div>
                        <div className={style.fat}>{customer}</div>
                    </div>
                    <div className={style.location}>
                        <div>
                            <div className={style.light_text}><FontAwesomeIcon icon={faLocationDot}/> РАСПОЛОЖЕНИЕ</div>
                        </div>
                        <div className={style.regular_text}>
                            {address}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default ObjectInfo