import React from "react";
import style from './NavBar.module.css'
import {NavLink} from "react-router-dom";

const NavBar = () => {
    return (
        <div className={style.nav_bar}>
            <ul className={style.nav_list}>
                <li className={style.nav_item}>
                    <NavLink to='/' className={({isActive}) => isActive ? style.active : ''}>Главная</NavLink>
                </li>
                <li className={style.nav_item}>
                    <NavLink to='/contacts' className={({isActive}) => isActive ? style.active : ''}>Контакты</NavLink>
                </li>
                <li className={style.nav_item}>
                    <NavLink to='/maps' className={({isActive}) => isActive ? style.active : ''}>Мы на карте</NavLink>
                </li>
                <li className={style.nav_item}>
                    <NavLink to='/documents' className={({isActive}) => isActive ? style.active : ''}>Документы</NavLink>
                </li>
            </ul>
        </div>
    )
}

export default NavBar;