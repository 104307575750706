import React from "react";
import style from './Object.module.css'
import Button from 'react-bootstrap/Button';
import {NavLink} from "react-router-dom";


const Object = (props) => {
    return (
        <div className={style.object}>
            <div className={style.object_img}><img src={props.img} alt={props.alt}/></div>
            <div className={style.object_info}>
                <h4>{props.name}</h4>
                <div className={style.object_text}>
                    <div className={style.date}>
                        {props.address}
                    </div>
                    <div className={style.light_text}>
                        <span className={style.light_text}>срок сдачи</span>
                        <ul>
                            <li><span className={style.list_dot} style={{background: props.dots_color}}></span>{props.date_start}</li>
                            <li><span className={style.list_dot} style={{background: props.dots_color}}></span>{props.date_end}</li>
                        </ul>
                    </div>
                </div>
                <div className={style.object_footer}>
                    <div>
                        <div className={style.light_text}>кладка:</div>{props.bricks} м3
                    </div>
                    <div className={style.button}>
                        <Button variant="success"><NavLink to='/objectInfo' state = {{slides:props.slides, jk:props.jk, name:props.name,description:props.description,date_start:props.date_start, date_end:props.date_end, bricks:props.bricks, customer:props.customer, address:props.address, stages:props.stages}} className={({isActive}) => isActive ? style.object_active : ''}>Посмотреть</NavLink></Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Object