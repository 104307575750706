import React from "react";
import {NavLink} from "react-router-dom";
import style from "./Objects.module.css";
import Object from "./Object/Object";
import picture1 from '../images/01 Ручьи/20220610_102016.jpg'
import picture2 from '../images/02 Садик/20211203_135405.jpg'
import picture3 from '../images/04 Заповедная/20220416_111650.jpg'
import picture4 from '../images/05 Приневская2/IMG-20220705-WA0003.jpg'
import picture5 from '../images/06 Коломяжский/20220716_121230.jpg'
import picture6 from '../images/07 Невская Губа 2/IMG-20220714-WA0014.jpg'
import picture7 from '../images/08 Октябрьская 3/IMG-20220705-WA0011.jpg'
import picture8 from '../images/09 Приневская19/20221201_140846.jpg'
import img_1_1 from '../images/01 Ручьи/20220610_102016.jpg'
import img_1_2 from '../images/01 Ручьи/20220614_121258.jpg'
import img_1_3 from '../images/01 Ручьи/20220714_083225.jpg'
import img_2_1 from '../images/02 Садик/20211203_135405.jpg'
import img_2_3 from '../images/02 Садик/20211203_135522.jpg'
import img_2_4 from '../images/02 Садик/20211203_135801.jpg'
import img_3_1 from '../images/04 Заповедная/20220416_111650.jpg'
import img_3_2 from '../images/04 Заповедная/20220416_111732.jpg'
import img_4_1 from '../images/05 Приневская2/IMG-20220705-WA0003.jpg'
import img_4_2 from '../images/05 Приневская2/IMG-20220705-WA0001.jpg'
import img_4_3 from '../images/05 Приневская2/IMG-20220705-WA0002.jpg'
import img_4_4 from '../images/05 Приневская2/IMG-20220705-WA0002.jpg'
import img_4_5 from '../images/05 Приневская2/Приневская, гараж/IMG-20220416-WA0004.jpg'
import img_4_6 from '../images/05 Приневская2/Приневская, гараж/IMG-20220416-WA0005.jpg'
import img_5_1 from '../images/06 Коломяжский/20220716_121230.jpg'
import img_5_2 from '../images/06 Коломяжский/20220716_121258.jpg'
import img_6_1 from '../images/07 Невская Губа 2/IMG-20220714-WA0014.jpg'
import img_7_1 from '../images/08 Октябрьская 3/IMG-20220705-WA0011.jpg'
import img_7_2 from '../images/08 Октябрьская 3/IMG-20220705-WA0015.jpg'
import img_7_3 from '../images/08 Октябрьская 3/IMG-20220705-WA0016.jpg'
import img_8_1 from '../images/09 Приневская19/20221201_140846.jpg'
import img_8_2 from '../images/09 Приневская19/20221201_140950.jpg'
import img_8_3 from '../images/09 Приневская19/20221201_141033.jpg'
import img_8_4 from '../images/09 Приневская19/20221201_141117.jpg'





const Objects = (props) => {

    return (
        <div className={style.objects}>
            <h1> Наши Объекты</h1>
            <div className={style.objects_container}>
                {/*slides:props.slides, jk:props.jk, name:props.name,
                description:props.description,date_start:props.date_start,
                date_end:props.date_end, bricks:props.bricks, customer:props.customer,
                address:props.address*/}
                <Object img={picture1}
                        dots_color={'rgb(194, 104, 104)'}
                        name = 'Ручьи'
                        slides={[img_1_1,img_1_2,img_1_3]}
                        jk='ЖК "Северный"'
                        description='Многоквартирные жилые дома со встроенными помещениями обслуживания, подземными и надземными автотоянками'
                        date_start='Март 2021'
                        date_end='Март 2023'
                        bricks='36,164'
                        customer='ЦДС'
                        address='Ленинградская область, Всеволожский район, земли САОЗТ «Ручьи», кадастровый номер земельного участка: 47:07:0722001:70 '
                        stages={["2 этап -  Корпуса 4,5", "3 этап - Корпуса 6, 7", "4 этап - Корпуса 8, 9", "6 этар - Корпус 10", "7 этап - Корпус 12"]}
                />
                <Object img={picture2}
                        dots_color={'#7f98ca'}
                        name = 'Садик'
                        slides={[img_2_1, img_2_3, img_2_4]}
                        jk='ЖК "Звезды столицы"'
                        description='Объект дошкольного образовательного учреждения на 250 мест'
                        date_start='Май 2021'
                        date_end='Июнь 2021'
                        bricks='1,440'
                        customer='ООО "ИнтерГрупп"'
                        address='г. Санкт-Петербург, Московское шоссе, участок 174'
                        stages={[]}
                />
                <Object img={picture3}
                        dots_color={'rgb(200, 138, 66)'}
                        name = 'Заповедная'
                        slides={[img_3_1, img_3_2]}
                        jk=''
                        description='Научно-клинический нейрохирургический комплекс федерального государственного бюджетного учреждения «Северо-Западный федеральный медицинский исследовательский центр имени В.А.Алмазова» Министерства здравоохранения Российской Федерации'
                        date_start='Сентябрь 2021'
                        date_end='Август 2022'
                        bricks='3,968'
                        customer='Министерство здравоохранения'
                        address=' г. Санкт-Петербург, Заповедная улица, участок 1 (напротив дома 41, литера А по Заповедной улице)'
                        stages={[]}
                />
                <Object img={picture4}
                        dots_color={'#7f98ca'}
                        name = 'Приневская 2'
                        slides={[img_4_1, img_4_2,img_4_3, img_4_4,img_4_5, img_4_6]}
                        jk=''
                        description='Многоквартирный дом, встроено-пристроенные помещения, пристроенный гараж. Корпус 2, паркинг'
                        date_start='Октябрь 2021'
                        date_end='Август 2022'
                        bricks='8,189'
                        customer='ЦДС'
                        address='Санкт-Петербург, Октябрьская набережная, участок 2,3, кадастровый номер земельного участка 78:12:0006355:3582'
                        stages={[]}
                />
                <Object img={picture5}
                        dots_color={'rgb(200, 138, 66)'}
                        name = 'Коломяжский'
                        slides={[img_5_1, img_5_2]}
                        jk=''
                        description='Научно-образовательный комплекс федерального государственного бюджетного учреждения «Национальный медицинский исследовательский центр имени В.А. Алмазова'
                        date_start='Апрель 2022'
                        date_end='Август 2022'
                        bricks='1,586'
                        customer='Министерства здравоохранения РФ'
                        address='Санкт-Петербург, Коломяжский пр., участок 1'
                        stages={[]}
                />
                <Object img={picture6}
                        dots_color={'rgb(194, 104, 104)'}
                        name = 'Невская Губа 2'
                        slides={[img_6_1]}
                        jk=''
                        description='Многоквартирный дом со встроенно-пристроенными помещениями и подземным гаражом (автостоянкой)'
                        date_start='Апрель 2022'
                        date_end='Январь 2023'
                        bricks='9267'
                        customer='ООО "ЛСР. Недвижимость С-З"'
                        address='г. Санкт-Петербург, Невская губа, участок 14, земельный участок 49 по ППТ, кадастровый номер 78:43:0000000:31'
                        stages={[ ' - 1, 2 этапы', '2 этап строительства - Жилой блок 12.1']}
                />
                <Object img={picture7}
                        dots_color={'#7f98ca'}
                        name = 'Октябрьская 3'
                        slides={[img_7_1, img_7_2, img_7_3]}
                        jk='ЖК "Цивилизация"'
                        description='Многоквартирный дом со встроенно-пристроенными помещениями и встроенно-пристроенным многоэтажным гаражом (автостоянкой)'
                        date_start='Июнь 2022'
                        date_end='Май 2023'
                        bricks='14,062'
                        customer='ООО "ЛСР. Недвижимость С-З"'
                        address=' г. Санкт-Петербург,Октябрьская набережная, дом 40, литера А, кадастровый номер 78:12:0633102:4179 (земельный участок №2 по ППТ)'
                        stages={[]}
                />
                <Object img={picture8}
                        dots_color={'rgb(200, 138, 66)'}
                        name = 'Приневская 19'
                        slides={[img_8_1,img_8_2,img_8_3,img_8_4]}
                        jk=''
                        description='Многоквартирный дом, встроенно-пристроенный многоэтажный гараж. Корпус 19'
                        date_start='Декабрь 2022'
                        date_end='Март 2023'
                        bricks='8,204'
                        customer='ЦДС'
                        address='г. Санкт-Петербург, территория предприятия "Преневское", участок 1 (кадастровый номер 78:12:0006355:3139)'
                        stages={[]}
                />
            </div>
        </div>
    )
}

export default Objects