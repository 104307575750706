import React from "react";
import style from "./Footer.module.css";
import {NavLink} from "react-router-dom";

const Footer = () => {
    const tel = 'tel:' + '+7 (964) 328 66 61'
    return (
        <div className={style.footer}>
            <div className={style.footer_container}>
                <div className={style.about_us}>
                    <h3 className={style.footer_header}>О нас</h3>
                    <ul className={style.footer_list}>

                        <li className={style.footer_list_item}><NavLink to='/documents' className={({isActive}) => isActive ? style.active : ''}>Документы</NavLink></li>
                        <li className={style.footer_list_item}><NavLink to='/maps' className={({isActive}) => isActive ? style.active : ''}>Мы на карте</NavLink></li>
                    </ul>
                </div>
                <div className={style.contacts}>
                    <h3 className={style.footer_header}>Контакты</h3>
                    <ul className={style.footer_list}>
                        <li className={style.footer_list_item}><NavLink to='/contacts' className={({isActive}) => isActive ? style.active : ''}>Контакты</NavLink></li>
                        <li className={style.footer_list_item}><a href={tel}>+7(964)328-66-61</a></li>
                        <li className={style.footer_list_item}><a href="#">RegionS-Z@yandex.ru</a></li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default Footer;