import React from "react";
import style from "./Maps.module.css";


const Maps = ()=>{
    return(
        <div className={style.maps}>
            <iframe
                src="https://yandex.ru/map-widget/v1/?um=constructor%3A9501735f83896fef733e2f4bac7d47615759ab79ece33cd77f5c7d8592571736&amp;source=constructor"
                width="1200" height="640" frameBorder="0"></iframe>
        </div>
    )
}

export default Maps;