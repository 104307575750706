import React from "react";
import style from "./Contact_footer.module.css";

const Contact_footer = () => {
    return (
        <div className={style.contact_footer}>
            <div className={style.contact_footer_container}>
                <div>
                    Copyright © 2023. All rights are reserved
                </div>
                <div className={style.phone_number}>
                    Позвоните нам: +7(964)328-66-61
                </div>
                <div>
                    <a className={style.icon_link} href="https://www.flaticon.com/ru/free-icons/" title="строительство иконки">Строительство иконки от Freepik - Flaticon</a>
                </div>
            </div>
        </div>
    )
}


export default Contact_footer;