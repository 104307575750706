import './App.css';
import Header from "./Header/Header";
import Home from "./Home/Home";
import Footer from "./Footer/Footer";
import Contact_footer from "./Contact_footer/Contact_footer";
import Maps from "./Maps/Maps";
import Contacts from "./Contacts/Contacts";
import Documents from "./Documents/Documents";
import {
    BrowserRouter as Router,
    Routes,
    Route
} from "react-router-dom";
import NotFound from "./NotFound/NotFound";
import React from "react";
import ObjectInfo from "./ObjectInfo/ObjectInfo";


function App() {
    return (
        <Router>
            <div className="App">

                <Header/>
                <div className='main_page'>
                    <Routes>
                        <Route path="*" element={<NotFound />} />
                        <Route path='/' element={<Home/>} />
                        <Route path='/maps' element={<Maps/>} />
                        <Route path='/contacts' element={<Contacts/>} />
                        <Route path='/documents' element={<Documents/>} />
                        <Route path='/objectInfo' element={<ObjectInfo/>} />
                    </Routes>
                </div>
                <Footer/>
                <Contact_footer/>
            </div>
        </Router>
    );
}

export default App;
