import React from "react";
import style from "./Documents.module.css";
import AdditionalDocument from "./AdditionalDocument/AdditionalDocument";
import Accordion from 'react-bootstrap/Accordion';
import document1 from './src/ИНН от 28.01.2021.pdf'
import document2 from './src/ОГРН.pdf'
import document3 from './src/Приказ № 1 на Ген. директора.pdf'
import document4 from './src/Решение № 1 от 20.01.2021.pdf'
import document5 from './src/Свидетельство о постановке на налоговый учет.pdf'
import document6 from './src/Устав .pdf'


const Documents = () => {

    return (
        <Accordion defaultActiveKey={['0']} alwaysOpen className={style.documents}>
            <h2 className={style.documents_title}>Документы</h2>
            <div className={style.other_documents}>
                <AdditionalDocument name='ИНН от 28.01.2021' href={document1}/>
                <AdditionalDocument name='ОГРН.pdf' href={document2}/>
                <AdditionalDocument name='Приказ № 1 на Ген. директора.pdf' href={document3}/>
                <AdditionalDocument name='Решение № 1 от 20.01.2021.pdf' href={document4}/>
                <AdditionalDocument name='Свидетельство о постановке на налоговый учет.pdf' href={document5}/>
                <AdditionalDocument name='Устав.pdf' href={document6}/>
            </div>
        </Accordion>

    )
}


export default Documents;